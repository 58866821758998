import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import { Container } from "react-bootstrap"
import TagsLink from "../components/TagsLink"
const _ = require("lodash")

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges.filter(
    edge => edge.node.frontmatter.template === "post"
  )
  const tags = data.tagsPostGroup.group

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Spiritual" />
      <Container className="tags_header">
        <h2 className="title_font">My Spiritual Activity</h2>
        <ul className="tags_bar">
          <TagsLink to="/pproject/" children="all" />
          {tags.map(tag => {
            return (
              <TagsLink
                to={`/p-tags/${_.kebabCase(tag.fieldValue)}/`}
                children={tag.fieldValue}
              />
            )
          })}
        </ul>
      </Container>
      <Container className="work_page">
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          const featuredImgFluid = node.frontmatter.img
          const year = new Date(node.frontmatter.date).getFullYear()
          const month = new Date(node.frontmatter.date).getMonth() + 1
          return (
            <article
              key={node.fields.slug}
              className="article_list_item"
              // style={{
              // marginTop: `-15px`,
              // marginBottom: `70px`,
              // color: "#fff"
              // }}
            >
              <header>
                <h3 style={{ marginBottom: 0 }}>
                  <Link
                    style={{
                      boxShadow: `none`,
                      color: "#fff",
                      fontSize: `21px`,
                      fontWeight: `400`,
                      // fontFamily: `Montserrat`,
                    }}
                    to={node.fields.slug}
                  >
                    {title}
                  </Link>
                </h3>
                {/* <small
                style={{
                  color: "#B7B7B7",
                  fontSize: `17px`,
                  // fontWeight: `300`,
                }}
              >
                {year}
              </small> */}
              </header>
              <section
                style={{
                  color: "#ef00ef",
                  fontSize: `17px`,
                  fontWeight: `300`,
                }}
              >
                <p className="article_year">{month}/{year}</p>
                <p
                  className="article_des"
                  style={{ marginBottom: `5px` }}
                  dangerouslySetInnerHTML={{
                    __html: node.frontmatter.description || node.excerpt,
                  }}
                />
                <Link
                  style={{
                    boxShadow: `none`,
                    color: "#fff",
                    fontSize: `21px`,
                    fontWeight: `400`,
                    fontFamily: `Montserrat`,
                  }}
                  to={node.fields.slug}
                >
                  {node.frontmatter.img && (
                    <Img fluid={featuredImgFluid.childImageSharp.fluid} />
                  )}
                </Link>
              </section>
            </article>
          )
        })}
      </Container>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    tagsPostGroup: allMarkdownRemark(
      limit: 2000
      filter: { frontmatter: { template: { eq: "post" } } }
    ) {
      group(field: frontmatter___tags) {
        fieldValue
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            template
            title
            tags
            description
            slug
            category
            img {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
