import React from "react"
import { Link } from "gatsby"

const TagsLink = props => (
  <li style={{ display: `inline-block`, marginRight: `1rem` }}>
    <Link
      to={props.to.slice(-1) === "/" ? props.to.slice(0,-1) : props.to}
      className="tags_link"
      activeClassName="current_tag_link"
      partiallyActive={true}
    >
      {props.children}
    </Link>
  </li>
)

export const RightTagsLink = props => (
  <li style={{ display: `inline-block`, marginRight: `1rem` }}>
    <Link
      to={props.to.slice(-1) === "/" ? props.to.slice(0,-1) : props.to}
      className="tags_link"
      activeClassName="current_tag_link"
      partiallyActive={true}
    >
      {props.children}
    </Link>
  </li>
)

export default TagsLink;